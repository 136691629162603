import { useContext, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FormHelperText, Grid, LinearProgress } from '@mui/material';
import {
  AssignmentOutlined,
  SchoolOutlined,
  ClassOutlined,
  PreviewOutlined,
  HelpOutline,
} from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import { ListButton, BackButton } from '../../components/buttons';
import NavigationBar from '../../components/navigations/navigation-bar/NavigationBar';
import DividedContainer from '../../components/containers/divided-container/DividedContainer';
import useMyPublication from './useMyPublication';
import PublicationChip from '../../components/chips/publication-chip/PublicationChip';
import FlagContext from '../../contexts/FlagContext';
import getPublicationId from '../../utils/getPublicationId';
import PageSubtitle from '../../components/typographies/PageSubtitle';
import PageTitle from '../../components/typographies/PageTitle';
import useAnalytics from '../../hooks/useAnalytics/useAnalytics';

const MyPublication = () => {
  const { publicationsAvailable } = useContext(FlagContext);
  const { traceBtnClick } = useAnalytics();

  const { t } = useTranslation(['my-publication']);
  const { identifier } = useParams();
  const { fetchAuthPublication, data, status } = useMyPublication();

  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    return () => {
      sessionStorage.setItem('from', location.pathname);
    };
  }, []);

  useEffect(() => {
    const id = getPublicationId(identifier as string);
    const attributes = [
      'id',
      'type',
      'title',
      'state',
      'active',
      'lastGroupDate', 
      'lastGroupStartTime', 
      'agenda',
      'quotaSold',
      'maxQuota',
      'agenda',
      'quota',
    ];
    fetchAuthPublication(id, attributes);
  }, []);

  return (
    <>
      {!!identifier && status === 'loading' && <LinearProgress />}
      {!!identifier && status !== 'loading' && !!data && (
        <DividedContainer>
          <NavigationBar variant="variant4" />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <PageTitle>{`${t(`title.${data.type}`, '') || t('subtitle.class')}: ${data.title}`}</PageTitle>
            </Grid>
            <Grid item xs={12}>
              {data.state === 'draft' ? (
                <PageSubtitle>{t('subtitle.draft')}</PageSubtitle>
              ) : (
                <PageSubtitle>{t('subtitle.rest')}</PageSubtitle>
              )}
            </Grid>
            {data.state !== 'draft' && (
              <Grid item xs={12}>
                <ListButton
                  title={t('options.state')}
                  hideIcon
                >
                  {data.state === 'approved'
                    ? <PublicationChip type={data?.active ? 'active' : 'no-active'} />
                    : <PublicationChip type={data.state} />}
                </ListButton>
              </Grid>
            )}
            {data.state === 'rejected' && (
              <Grid item xs={12}>
                <FormHelperText error>
                  {t('helperText.rejected') as string}
                </FormHelperText>
              </Grid>
            )}
            <Grid item xs={12}>
              <ListButton
                title={t('options.information')}
                onClick={() => {
                  traceBtnClick({
                    step: 'step ir a detalles de publicación',
                    product: 'Detalles de publicación',
                  });
                  navigate(`/my-publication/${identifier}/details`);
                }}
                icon={<AssignmentOutlined />}
              />
            </Grid>
            <Grid item xs={12}>
              {(data?.state === 'approved' && publicationsAvailable && data?.active) ? (
                <ListButton
                  title={t('options.view')}
                  onClick={() => {
                    traceBtnClick({
                      step: 'step ir a vista de publicación',
                      product: 'Vista de publicación',
                    });
                    navigate(`/publication/${identifier}`);
                  }}
                  icon={<ClassOutlined />}
                />
              ) : (
                <ListButton
                  title={t('options.preview')}
                  onClick={() => {
                    traceBtnClick({
                      step: 'step ir a previsualización de publicación',
                      product: 'previsualización de publicación',
                    });
                    window.open(`/my-publication/${identifier}/preview`, '_blank');
                  }}
                  icon={<PreviewOutlined />}
                />
              )}
            </Grid>
            {data.state === 'approved' && (
              <Grid item xs={12}>
                <ListButton
                  title={t('options.explorer')}
                  onClick={() => {
                    traceBtnClick({
                      step: 'step ir a exploradores de publicación',
                      product: 'Exploradores de publicación',
                    });
                    navigate(`/my-publication/${identifier}/explorers`);
                  }}
                  icon={<SchoolOutlined />}
                />
              </Grid>
            )}
            {data.state === 'approved' && (
              <Grid item xs={12}>
                <ListButton
                  title={t('options.questionAnswer')}
                  onClick={() => {
                    traceBtnClick({
                      step: 'step ir a preguntas y respuestas de publicación',
                      product: 'Preguntas y respuestas de publicación',
                    });
                    navigate(`/my-publication/${identifier}/question-answer`);
                  }}
                  icon={<HelpOutline />}
                />
              </Grid>
            )}
            <Grid item paddingTop={2}>
              <BackButton onClick={() => { 
                traceBtnClick({
                  step: 'step volver a mis publicaciones',
                  product: 'Volver',
                });
                const previousPage = '/my-publications';
                const from = sessionStorage.getItem('from');
                return (from === previousPage ) ? navigate(-1) : navigate(previousPage);
              }} />
            </Grid>
          </Grid>
        </DividedContainer>
      )}
    </>
  );
};

export default MyPublication;
