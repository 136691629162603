import { forwardRef } from 'react';
import { QuestionAnswerEntity } from '../../../entities';
import { Label } from '../../typographies';
import AnswerIcon from '@mui/icons-material/SouthEast';
import Paragraph from '../../typographies/Paragraph';

interface Props {
  questionAnswers?: QuestionAnswerEntity[],
}

const Component = forwardRef<HTMLDivElement, Props>(
  ({ questionAnswers = [], ...rest }, ref) => {
    if (!questionAnswers || questionAnswers.length === 0) {
      return null;
    }
    return (
      <div {...rest} ref={ref}>
        <Paragraph fontWeight={500} textTransform={'uppercase'} marginBottom={1} marginTop={1}>
          Últimas preguntas realizadas
        </Paragraph>
        {questionAnswers.map((questionAnswer) => (
          <div key={questionAnswer.id} style={{ marginBottom: 12 }}>
            <Label>{questionAnswer.question}</Label>
            <div style={{ display: 'flex', gap: 4 }}>
              <AnswerIcon fontSize='small'/>
              <div style={{ width: '100%' }}>
                <Paragraph style={{ opacity: 0.6 }}>{questionAnswer.answer}</Paragraph>
                <Paragraph fontSize={14} style={{ opacity: 0.6, marginLeft: 4, display: 'flex', justifyContent: 'flex-end' }}>
                  {questionAnswer?.updatedAtFormat?.toString()}
                </Paragraph>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  });

export default Component;




