import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import usePublication from './usePublication';
import { PublicationTemplate } from '../../components/publication/publication-template/PublicationTemplate';
import { LinearProgress } from '@mui/material';
import SimpleError from '../../components/errors/simple-error/SimpleError';
import getPublicationId from '../../utils/getPublicationId';
import Groups from '../../components/publication/groups/Groups';

const Publication = () => {
  const { identifier } = useParams();
  const { fetchPublication, data, status, error } = usePublication();

  useEffect(() => {
    const attributes = [
      'id',
      'type',
      'title',
      'description',
      'detail',
      'material',
      'price',
      'mode',
      'modeDetail',
      'location',
      'knowledges',
      'knowledgeDetail',
      'categoryId',
      'category',
      'audiovisuals',
      'state',
      'active',
      'createdById',
      'createdBy',
      'ageranges',
      'groups',
      'groups.sessions',
      'groups.checkoutItems',
      'groups.checkoutItems.checkout',
      // 'groups.weekly',
      'lastGroupDate', 
      'lastGroupStartTime', 
      'agenda',
      'minDuration',
      'minDurationFormat',
      'maxDuration',
      'maxDurationFormat',
      'minQuota',
      'maxQuota',
      'questionAnswers',
      'evaluation',
      'evaluationCount',
      'reviews',
      'reviewsFormat',
      'quota',
    ];
    const id = getPublicationId(identifier as string);
    fetchPublication(id, attributes);
  }, []);
  return (
    <>
      {status === 'loading' && <LinearProgress />}
      {status === 'loaded' && data && (
        <PublicationTemplate publication={data}>
          <Groups groups={data?.groups}/>
        </PublicationTemplate>
      )}
      {status === 'loaded' && error && (
        <SimpleError>{error?.message}</SimpleError>
      )}
    </>
  );
};

export default Publication;